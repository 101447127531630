import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.css']
})
export class PrivacyPageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Privacy policy - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Privacy policy - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/privacy" });

  }

}
