import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { service } from './../models/service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  private ServicesUrl = 'https://grantit.nl/api/services';
  private SingleServiceUrl = 'https://grantit.nl/api/service';

  public getServices(): Observable<any> {
    return this.http.get(this.ServicesUrl, {headers: {'Authorization': 'Bearer ' + this.authenticationService.token}});
  }

  public getServiceById(serviceId:number):Observable<any>{
    const url = `${this.SingleServiceUrl}/${serviceId}`;
    return this.http.get(url);
  }

}
