import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-service-info-contact-button',
  templateUrl: './service-info-contact-button.component.html',
  styleUrls: ['./service-info-contact-button.component.css']
})
export class ServiceInfoContactButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
