﻿import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ict-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    constructor(private authenticationService: AuthenticationService,private router: Router) { }

    private isLoggedIn = false;

    ngOnInit() {
      if (localStorage.getItem('currentUser') != '' && localStorage.getItem('currentUser') != null) {
        this.isLoggedIn = true;
      }
    }

    logout(){
      this.authenticationService.logout();
      console.log("logging out");
    }

}
