import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { MainContentComponent } from './home-page/main-content/main-content.component';
import { TopbarComponent } from './shared/components/topbar/topbar.component';
import { MobileHeaderComponent } from './shared/components/mobile-header/mobile-header.component';
import { SliderComponent } from './home-page/slider/slider.component';
import { FeaturesComponent } from './home-page/features/features.component';
import { AboutComponent } from './home-page/about/about.component';
import { ServicesComponent } from './home-page/services/services.component';
import { ChooseUsComponent } from './home-page/choose-us/choose-us.component';
import { BlogItemsComponent } from './home-page/blog-items/blog-items.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { CopyrightComponent } from './shared/components/copyright/copyright.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ContactContentComponent } from './contact-page/contact-content/contact-content.component';
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';
import { TeamComponent } from './contact-page/team/team.component';
import { ContactformComponent } from './shared/components/contactform/contactform.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { ServicesContentComponent } from './services-page/services-content/services-content.component';
import { ServicesListingComponent } from './services-page/services-listing/services-listing.component';
import { ServiceInfoPageComponent } from './service-info-page/service-info-page.component';
import { ServiceInfoContentComponent } from './service-info-page/service-info-content/service-info-content.component';
import { ServiceInfoLeftComponent } from './service-info-page/service-info-left/service-info-left.component';
import { ServiceInfoRightComponent } from './service-info-page/service-info-right/service-info-right.component';
import { ServiceInfoFilesComponent } from './service-info-page/service-info-files/service-info-files.component';
import { ServiceInfoOthersComponent } from './service-info-page/service-info-others/service-info-others.component';
import { ServiceInfoContactButtonComponent } from './service-info-page/service-info-contact-button/service-info-contact-button.component';
import { ServiceInfoAccordionComponent } from './service-info-page/service-info-accordion/service-info-accordion.component';
import { ServiceInfoFeaturesComponent } from './service-info-page/service-info-features/service-info-features.component';
import { ServiceInfoCategoriesComponent } from './service-info-page/service-info-categories/service-info-categories.component';
import { ServiceInfoOfferteComponent } from './service-info-page/service-info-offerte/service-info-offerte.component';
import { OverOnsPageComponent } from './over-ons-page/over-ons-page.component';
import { OverOnsContentComponent } from './over-ons-page/over-ons-content/over-ons-content.component';
import { OverOnsIntroComponent } from './over-ons-page/over-ons-intro/over-ons-intro.component';
import { HttpClientModule } from '@angular/common/http';
import { LoadScriptsComponent } from './shared/components/load-scripts/load-scripts.component';
import { ProductenPageComponent } from './producten-page/producten-page.component';
import { ProductenContentComponent } from './producten-page/producten-content/producten-content.component';
import { RegistreerPageComponent } from './registreer-page/registreer-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RegistreerContentComponent } from './registreer-page/registreer-content/registreer-content.component';
import { LoginContentComponent } from './login-page/login-content/login-content.component';
import { ProductInfoPageComponent } from './product-info-page/product-info-page.component';
import { ProductInfoContentComponent } from './product-info-page/product-info-content/product-info-content.component';
import { NieuwsPageComponent } from './nieuws-page/nieuws-page.component';
import { NieuwsContentComponent } from './nieuws-page/nieuws-content/nieuws-content.component';
import { NieuwsInfoPageComponent } from './nieuws-info-page/nieuws-info-page.component';
import { NieuwsInfoContentComponent } from './nieuws-info-page/nieuws-info-content/nieuws-info-content.component';
import { OffertePageComponent } from './offerte-page/offerte-page.component';
import { OfferteContentComponent } from './offerte-page/offerte-content/offerte-content.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ProfileContentComponent } from './profile-page/profile-content/profile-content.component';

import { GeneralService } from './shared/services/general.service';
import { ServicesService } from './shared/services/services.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { RegisterService } from './shared/services/register.service';
import { PaymentService} from './shared/services/payment.service';
import { ArticleService } from './shared/services/article.service';
import { UserService } from './shared/services/user.service';
import { ContactService } from './shared/services/contact.service';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';
import { PrivacyContentComponent } from './privacy-page/privacy-content/privacy-content.component';
import { VoorwaardenPageComponent } from './voorwaarden-page/voorwaarden-page.component';
import { VoorwaardenContentComponent } from './voorwaarden-page/voorwaarden-content/voorwaarden-content.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SidebarComponent,
    MainContentComponent,
    TopbarComponent,
    MobileHeaderComponent,
    SliderComponent,
    FeaturesComponent,
    AboutComponent,
    ServicesComponent,
    ChooseUsComponent,
    BlogItemsComponent,
    FooterComponent,
    CopyrightComponent,
    ContactPageComponent,
    ContactContentComponent ,
    BreadcrumbsComponent ,
    TeamComponent,
    ContactformComponent,
    ServicesPageComponent,
    ServicesContentComponent,
    ServicesListingComponent,
    ServiceInfoPageComponent ,
    ServiceInfoContentComponent ,
    ServiceInfoLeftComponent,
    ServiceInfoRightComponent,
    ServiceInfoFilesComponent ,
    ServiceInfoOthersComponent ,
    ServiceInfoContactButtonComponent,
    ServiceInfoAccordionComponent,
    ServiceInfoFeaturesComponent,
    ServiceInfoCategoriesComponent ,
    ServiceInfoOfferteComponent ,
    OverOnsPageComponent,
    OverOnsContentComponent ,
    OverOnsIntroComponent,
    LoadScriptsComponent ,
    ProductenPageComponent ,
    ProductenContentComponent,
    RegistreerPageComponent ,
    LoginPageComponent ,
    RegistreerContentComponent,
    LoginContentComponent,
    ProductInfoPageComponent ,
    ProductInfoContentComponent ,
    NieuwsPageComponent,
    NieuwsContentComponent ,
    NieuwsInfoPageComponent ,
    NieuwsInfoContentComponent,
    OffertePageComponent ,
    OfferteContentComponent ,
    ProfilePageComponent,
    ProfileContentComponent ,
    PrivacyPageComponent ,
    PrivacyContentComponent
,
    VoorwaardenPageComponent ,
    VoorwaardenContentComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [AuthenticationService,GeneralService,ServicesService,RegisterService,PaymentService,ArticleService,UserService,ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
