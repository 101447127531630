import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-offerte-page',
  templateUrl: './offerte-page.component.html',
  styleUrls: ['./offerte-page.component.css']
})
export class OffertePageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Offerte aanvragen - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Offerte aanvragen - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/offerte" });

  }

}
