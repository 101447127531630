import { Component, OnInit } from '@angular/core';
import { GeneralService } from './../../services/general.service';

@Component({
  selector: 'ict-load-scripts',
  templateUrl: './load-scripts.component.html',
  styleUrls: ['./load-scripts.component.css']
})
export class LoadScriptsComponent implements OnInit {

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.loadScript();
  }

}