import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-contact-content',
  templateUrl: './contact-content.component.html',
  styleUrls: ['./contact-content.component.css']
})
export class ContactContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
