﻿import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

    constructor() { }

    public loadScript() {
        let body = <HTMLDivElement>document.body;
        let script = document.createElement('script');
        script.innerHTML = '';
        script.src = './../../assets/js/init.js';
        script.async = true;
        script.defer = true;
        body.appendChild(script);
    }

}
