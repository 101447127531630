import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { userinfo } from './../models/userinfo';
import { AuthenticationService } from './authentication.service';
import { user } from './../models/user';
import { order } from './../models/order';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  private userinfoUrl = 'https://grantit.nl/api/userinfo';
  private userUrl = 'https://grantit.nl/api/user';
  private userOrdersUrl = 'https://grantit.nl/api/userorders';

  public getUserInfo(id: number): Observable<userinfo> {
    const url = `${this.userinfoUrl}/${id}`;
    return this.http.get<userinfo>(url, { headers: { 'Authorization': 'Bearer ' + this.authenticationService.token['token'] } });
  }

  getUserData(): Observable<user> {
    return this.http.get<user>(this.userUrl, { headers: { 'Authorization': 'Bearer ' + this.authenticationService.token['token'] } });
  }

  public getUserOrders(id: number): Observable<order[]> {
    const url = `${this.userOrdersUrl}/${id}`;
    return this.http.get<order[]>(url, { headers: { 'Authorization': 'Bearer ' + this.authenticationService.token['token'] } });
  }

}
