import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Home - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Home - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/" });

  }

}
