import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './authentication.service';
import { product } from './../models/product';
import { order } from './../models/order';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  private paymentUrl = 'https://grantit.nl/api/payment';
  private productsUrl = 'https://grantit.nl/api/products';
  private singleProductUrl = 'https://grantit.nl/api/product';
  private createOrderUrl = 'https://grantit.nl/api/createorder';

  public getPayment(prod:product): Observable<any> {
    console.log("begin betaling");
    
    return this.http.get(this.paymentUrl,
      {
        headers: {'Authorization': 'Bearer ' + this.authenticationService.token['token']},
        params: {'name':prod.ProductName,'price': Number(prod.Price).toFixed(2)}
      }
    );
  }

  public getProductById(productId:number):Observable<any>{
    const url = `${this.singleProductUrl}/${productId}`;
    return this.http.get(url);
  }

  public getProducts(): Observable<any> {
    return this.http.get(this.productsUrl);
  }

  public createOrder(order:order): Observable<any> {
    return this.http.post(this.createOrderUrl, order, {
      headers: {'Authorization': 'Bearer ' + this.authenticationService.token['token']}
    });
  }

}
