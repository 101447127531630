import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-nieuws-page',
  templateUrl: './nieuws-page.component.html',
  styleUrls: ['./nieuws-page.component.css']
})
export class NieuwsPageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Nieuws - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Nieuws - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/nieuws/" });

  }

}
