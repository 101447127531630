import { Component, OnInit } from '@angular/core';
import { ArticleService } from './../../shared/services/article.service';
import { articleShort } from './../../shared/models/articleShort';

@Component({
  selector: 'ict-blog-items',
  templateUrl: './blog-items.component.html',
  styleUrls: ['./blog-items.component.css']
})
export class BlogItemsComponent implements OnInit {

  constructor(private articleService:ArticleService) { }

  private shortArticles:articleShort[];

  ngOnInit() {
    this.articleService.getArticlesShort().subscribe(result => this.shortArticles = result['data']);
  }

}
