import { Component, OnInit } from '@angular/core';
import { ServicesService } from './../../shared/services/services.service';
import { service } from './../../shared/models/service';

@Component({
  selector: 'ict-services-listing',
  templateUrl: './services-listing.component.html',
  styleUrls: ['./services-listing.component.css']
})
export class ServicesListingComponent implements OnInit {

  constructor(private servicesService: ServicesService) { }

  private services: service[];

  ngOnInit() {

    this.servicesService.getServices().subscribe(result => this.services = result['data']);

  }

}
