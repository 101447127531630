import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Contact - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Contact - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/contact/" });

    }

}
