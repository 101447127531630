import { Component } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Grant-It';

  onActivate(event) {
    window.scroll(0, 0);
  }

    constructor(public router: Router) {

        // subscribe to router events and send page views to Google Analytics
        this.router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');

            }

        });

    }

}
