import { Component, OnInit } from '@angular/core';
import { ArticleService } from './../../shared/services/article.service';
import { article } from './../../shared/models/article';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ict-nieuws-info-content',
  templateUrl: './nieuws-info-content.component.html',
  styleUrls: ['./nieuws-info-content.component.css']
})
export class NieuwsInfoContentComponent implements OnInit {

    constructor(private articleService: ArticleService, private route: ActivatedRoute) { }

  private currentArticle:article;
  private articleNr: number;
  private sub: any;

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
        this.articleNr = +params['id'];
        this.articleService.getSingleArticle(this.articleNr).subscribe(result => this.currentArticle = result['data']);
      });
  }

}
