import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { ServiceInfoPageComponent } from './service-info-page/service-info-page.component';
import { OverOnsPageComponent } from './over-ons-page/over-ons-page.component';
import { ProductenPageComponent } from './producten-page/producten-page.component';
import { RegistreerPageComponent} from './registreer-page/registreer-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ProductInfoPageComponent } from './product-info-page/product-info-page.component';
import { NieuwsPageComponent } from './nieuws-page/nieuws-page.component';
import { NieuwsInfoPageComponent} from './nieuws-info-page/nieuws-info-page.component';
import { OffertePageComponent } from './offerte-page/offerte-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';
import { VoorwaardenPageComponent } from './voorwaarden-page/voorwaarden-page.component';

const routes: Routes = [
    { path: 'over-ons', component: OverOnsPageComponent },
    { path: 'nieuws', component: NieuwsPageComponent },
    { path: 'nieuws/:id', component: NieuwsInfoPageComponent },
    { path: 'offerte', component: OffertePageComponent },
    { path: 'privacy', component: PrivacyPageComponent },
    { path: 'voorwaarden', component: VoorwaardenPageComponent },
    { path: 'service/:id', component: ServiceInfoPageComponent },
    // { path: 'product/:id', component: ProductInfoPageComponent },
    { path: 'services', component: ServicesPageComponent },
    // { path: 'profiel', component: ProfilePageComponent},
    // { path: 'login', component: LoginPageComponent },
    // { path: 'registreer', component: RegistreerPageComponent },
    // { path: 'producten', component: ProductenPageComponent },
    { path: 'contact', component: ContactPageComponent },
    { path: '', component: HomePageComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
