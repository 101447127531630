import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ContactService } from './../../services/contact.service';
import { contact } from './../../models/contact';

@Component({
  selector: 'ict-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {

  constructor(private fb: FormBuilder, private contactService:ContactService) { }

  contactForm: FormGroup;
  loading = false;
  error = '';
  statusMessage = "";
  
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.contactForm = this.fb.group({ // this.contactForm.get('name').value // example
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  submitContact(){
    this.statusMessage = "Uw formulier word verstuurd...";
    let newContact = new contact;
    newContact.FirstName = this.contactForm.get('name').value;
    newContact.LastName = this.contactForm.get('lastname').value;
    newContact.Email = this.contactForm.get('email').value;
    newContact.Phone = this.contactForm.get('phone').value;
    newContact.Message = this.contactForm.get('message').value;
    newContact.ContactType = "Contact formulier";

    this.contactService.registerNewUser(newContact).subscribe(result => this.checkResult(result));
  }

  checkResult(result:any){
    if(result['success']){
      this.statusMessage = "Uw contactformulier is successvol verzonden.";
      this.contactForm.reset();
    }else{
      
    }
  }

}
