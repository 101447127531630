import { Component, OnInit } from '@angular/core';
import { ServicesService } from './../../shared/services/services.service';
import { service } from './../../shared/models/service';

@Component({
  selector: 'ict-service-info-categories',
  templateUrl: './service-info-categories.component.html',
  styleUrls: ['./service-info-categories.component.css']
})
export class ServiceInfoCategoriesComponent implements OnInit {

  constructor(private servicesService: ServicesService) { }

  private services: service[];

  ngOnInit() {
    this.servicesService.getServices().subscribe(result => this.services = result['data']);
  }

}
