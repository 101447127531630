import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-service-info-content',
  templateUrl: './service-info-content.component.html',
  styleUrls: ['./service-info-content.component.css']
})
export class ServiceInfoContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
