import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ict-login-content',
  templateUrl: './login-content.component.html',
  styleUrls: ['./login-content.component.css']
})
export class LoginContentComponent implements OnInit {

  constructor(private router: Router,private fb: FormBuilder,private authenticationService: AuthenticationService) { }

  loginForm: FormGroup;
  loading = false;
  error = '';
  
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({ // <-- the parent FormGroup
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
      .subscribe(result => {
        if (result === true) {
          // login successful
          this.router.navigate(['/']);
        } else {
          // login failed
          this.error = 'Username or password is incorrect';
          this.loading = false;
        }
      });
  }

}