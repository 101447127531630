﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ict-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

    constructor(private router: ActivatedRoute, private newRouter: Router) { }

    private currentPage: string;
    private currentPath: string;

    ngOnInit() {
        this.currentPage = this.router.snapshot.url[0].path;
        this.currentPath = this.router.snapshot.url.toString();
    }

}
