import { Component, OnInit } from '@angular/core';
import { ServicesService } from './../../shared/services/services.service';
import { service } from './../../shared/models/service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ict-service-info-left',
  templateUrl: './service-info-left.component.html',
  styleUrls: ['./service-info-left.component.css']
})
export class ServiceInfoLeftComponent implements OnInit {

  constructor(private servicesService: ServicesService, private route: ActivatedRoute) { }

  private currentService: service;
  private srvNr: number;
  private sub: any;

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.srvNr = +params['id'];
      this.servicesService.getServiceById(this.srvNr).subscribe(result => this.currentService = result['data']);
    });
    

  }

}
