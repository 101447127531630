import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ict-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit, AfterViewInit {

  ngAfterViewInit(): void {
    
  }

  constructor() { }

  ngOnInit() {
  }



}
