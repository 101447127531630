import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ContactService } from './../../shared/services/contact.service';
import { contact } from './../../shared/models/contact';

@Component({
  selector: 'ict-offerte-content',
  templateUrl: './offerte-content.component.html',
  styleUrls: ['./offerte-content.component.css']
})
export class OfferteContentComponent implements OnInit {

  constructor(private fb: FormBuilder, private contactService:ContactService) { }

  offerteForm: FormGroup;
  loading = false;
  error = '';
  statusMessage = "";
  
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.offerteForm = this.fb.group({ // this.offerteForm.get('name').value // example
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  submitContact(){
    this.statusMessage = "Uw formulier word verstuurd...";
    let newContact = new contact;
    newContact.FirstName = this.offerteForm.get('name').value;
    newContact.LastName = this.offerteForm.get('lastname').value;
    newContact.Email = this.offerteForm.get('email').value;
    newContact.Phone = this.offerteForm.get('phone').value;
    newContact.Message = this.offerteForm.get('message').value;
    newContact.ContactType = "Offerte Aanvraag";

    this.contactService.registerNewUser(newContact).subscribe(result => this.checkResult(result));
  }

  checkResult(result:any){
    if(result['success']){
      this.statusMessage = "Uw offerte aanvraag is successvol verzonden.";
      this.offerteForm.reset();
    }else{
      
    }
  }

}
