import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Headers, RequestOptions, Response } from '@angular/http';
import { contact } from './../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  private registerUrl = 'https://grantit.nl/api/contact';

  registerNewUser(contact: contact): Observable<any> {
    return this.http.post(this.registerUrl, {
      contact: contact
    });
  } 
}
