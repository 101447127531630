import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-service-info-page',
  templateUrl: './service-info-page.component.html',
  styleUrls: ['./service-info-page.component.css']
})
export class ServiceInfoPageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Services - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Services - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/services" });

  }

}
