import {Component, OnInit} from '@angular/core';
import { ServicesService } from './../../shared/services/services.service';
import { service } from './../../shared/models/service';

@Component({
  selector: 'ict-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private servicesService: ServicesService) { }

  public services: service[];

  ngOnInit() {
    this.servicesService.getServices().subscribe(result => this.populateServices(result['data']));
  }

  private populateServices(result: any) {
    this.services = result;
    window.dispatchEvent(new Event('resize'));
  }

}
