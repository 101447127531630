﻿import { Component, OnInit } from '@angular/core';
import { GeneralService } from './../../services/general.service';

@Component({
  selector: 'ict-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.css']
})
export class CopyrightComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        
  }

}
