import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ict-over-ons-page',
  templateUrl: './over-ons-page.component.html',
  styleUrls: ['./over-ons-page.component.css']
})
export class OverOnsPageComponent implements OnInit {

    constructor(private titleSerice: Title, private meta: Meta) { }

    ngOnInit() {

        this.titleSerice.setTitle("Over ons - Grant IT");
        this.meta.updateTag({ property: 'og:title', content: "Over ons - Grant IT" });
        this.meta.updateTag({ property: 'og:url', content: "https://www.grantit.nl/#/over-ons" });

  }

}
