﻿import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-services-content',
  templateUrl: './services-content.component.html',
  styleUrls: ['./services-content.component.css']
})
export class ServicesContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      
  }

}
