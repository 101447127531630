import { Component, OnInit } from '@angular/core';
import { ArticleService } from './../../shared/services/article.service';
import { article } from './../../shared/models/article';

@Component({
  selector: 'ict-nieuws-content',
  templateUrl: './nieuws-content.component.html',
  styleUrls: ['./nieuws-content.component.css']
})
export class NieuwsContentComponent implements OnInit {

  constructor(private articleService:ArticleService) { }

  private articles:article[];

  ngOnInit() {
    this.articleService.getArticles().subscribe(result => this.articles = result['data']);
  }

}
