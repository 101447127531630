import { Component, OnInit } from '@angular/core';
import { product } from './../../shared/models/product';
import { PaymentService } from './../../shared/services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './../../shared/services/user.service';
import { user } from './../../shared/models/user';
import { order } from '../../shared/models/order';

@Component({
  selector: 'ict-product-info-content',
  templateUrl: './product-info-content.component.html',
  styleUrls: ['./product-info-content.component.css']
})
export class ProductInfoContentComponent implements OnInit {

  constructor(private payService: PaymentService, private route: ActivatedRoute, private userService:UserService) { }

  private currentProduct: product;
  private productNr: number;
  private sub: any;

  private curUser: user;

  ngOnInit() {
    
    this.sub = this.route.params.subscribe(params => {
      this.productNr = +params['id'];
      this.payService.getProductById(this.productNr).subscribe(result => this.currentProduct = result['data']);
    });

  }

  createPayment(){
    this.userService.getUserData().subscribe(result => this.curUser = result, err => console.log(err), () => this.fillPaymentInfo());
    this.payService.getPayment(this.currentProduct).subscribe(result => this.redirectToPaymentPage(result));
  }

  fillPaymentInfo(){
    let CurOrder = new order();
    CurOrder.OrderStatus = 1;
    CurOrder.ProductId = this.productNr;
    CurOrder.UserId = this.curUser.id;
    this.payService.createOrder(CurOrder).subscribe(result => console.log(result));
  }
  
  redirectToPaymentPage(payment:any){
    window.location.href = payment['paymentUrl'];
  }

}
