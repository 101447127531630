import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-service-info-right',
  templateUrl: './service-info-right.component.html',
  styleUrls: ['./service-info-right.component.css']
})
export class ServiceInfoRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
