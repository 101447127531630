import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { userinfo } from './../../shared/models/userinfo';
import { user } from './../../shared/models/user';
import { UserService } from './../../shared/services/user.service';
import { order } from './../../shared/models/order';
import { PaymentService } from './../../shared/services/payment.service';

@Component({
  selector: 'ict-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.css']
})
export class ProfileContentComponent implements OnInit {

  constructor(private userService: UserService, private paymentService: PaymentService) { }

  private userinfo:userinfo;
  private user:user;
  private orders: order[];

  ngOnInit() {
    //this.authenticationService.getUserInfo();
    this.userService.getUserData().subscribe(result => this.getAllUserInfo(result));
  }

  getAllUserInfo(res:any){

    this.user = res;
    console.log(res);
    this.userService.getUserInfo(res['id']).subscribe(result => this.setUserInfo(result), err => console.log(err), () => this.resizeFunction());
    this.userService.getUserOrders(res['id']).subscribe(result => this.orders = result['data'], err => console.log(err), () => this.resizeFunction());
  }

  setUserInfo(userInfoRes: any){
    this.userinfo = userInfoRes['data'];
    let additionalInfo: HTMLElement = document.getElementById("additionalInfo");
    additionalInfo.innerHTML = "<b>Extra informatie</b><br/>" + userInfoRes['data'].Additional;
  }

  resizeFunction(){
    window.dispatchEvent(new Event('resize'));
  }

}
// productContainer orderHistoryContainer
