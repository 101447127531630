import { Component, OnInit } from '@angular/core';
import { PaymentService } from './../../shared/services/payment.service';
import { Router } from '@angular/router';
import { product } from './../../shared/models/product';

@Component({
  selector: 'ict-producten-content',
  templateUrl: './producten-content.component.html',
  styleUrls: ['./producten-content.component.css']
})
export class ProductenContentComponent implements OnInit {

  constructor(private payService: PaymentService, private router: Router) { }
  
  private products : product[];

  ngOnInit() {
    this.payService.getProducts().subscribe(result => this.products = result['data']);
  }

}
