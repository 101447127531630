import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ict-over-ons-content',
  templateUrl: './over-ons-content.component.html',
  styleUrls: ['./over-ons-content.component.css']
})
export class OverOnsContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
