import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { user } from './../../shared/models/user';
import { RegisterService } from './../../shared/services/register.service';

@Component({
  selector: 'ict-registreer-content',
  templateUrl: './registreer-content.component.html',
  styleUrls: ['./registreer-content.component.css']
})
export class RegistreerContentComponent implements OnInit {

  constructor(private fb: FormBuilder,private regService: RegisterService) { }

  registerForm: FormGroup;
  private newUser = new user;

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group({ // <-- the parent FormGroup
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  registerUser(){
    if (!this.registerForm.invalid) {
      
      this.newUser.name = this.registerForm.controls['name'].value;
      this.newUser.lastname = this.registerForm.controls['lastname'].value;
      this.newUser.email = this.registerForm.controls['email'].value;
      this.newUser.phone = this.registerForm.controls['phone'].value;
      
      if(this.registerForm.controls['password'].value == this.registerForm.controls['confirmpassword'].value){
        this.newUser.password = this.registerForm.controls['password'].value;

        try {
          this.regService.registerNewUser(this.newUser).subscribe(result => console.log(result));
          //this.router.navigate(['/login']);
        } catch(e){
          console.log("something went wrong during registration." + e);
        }

      }else{
        console.log("the passwords dont match.");
      }

    } else {
      console.log("form is invalid");
    }
  }

}
