import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './authentication.service';
import { Headers, RequestOptions, Response } from '@angular/http';
import { user } from './../models/user';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  private registerUrl = 'https://grantit.nl/api/register';

  registerNewUser(usr: user): Observable<any> {
    return this.http.post(this.registerUrl, {
      user: usr
    });
  } 

}
