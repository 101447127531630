import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { articleShort} from './../models/articleShort';
import { article } from './../models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  private articlesURL = 'https://grantit.nl/api/articles';
  private articlesShortURL = 'https://grantit.nl/api/articlesShort';
  private SingleProductsURL = 'https://grantit.nl/api/article';

  getArticles(): Observable<article[]> {
    return this.http.get<article[]>(this.articlesURL)
  }

  getArticlesShort(): Observable<articleShort[]> {
    return this.http.get<articleShort[]>(this.articlesShortURL)
  }

  getSingleArticle(id: number): Observable<article> {
    const url = `${this.SingleProductsURL}/${id}`;
    return this.http.get<article>(url)
  }

}
